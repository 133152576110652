import * as React from "react"
import { Layout, _Section, HeadComponent } from "../components/shared"
import { graphql } from "gatsby"
import { Hero } from "../components/productsPage/shared"
import { prismicGetText, prismicGetRichText, extractPrismicNode, prismicGetRichHeader } from "../utils/prismicHelper"
import { PageWrapper } from "../utils/PageWrapper"
import { _h2 } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { JoinRadicle, OurValues, Team } from "../components/companyPage"
import { _externalLinkButton, _externalWhiteLinkButton } from "../components/shared/buttons"

export const prismicQuery = graphql`
    {
        prismic {
            ...companyFragment
        }
    }
`

interface CompanyPageProps {
    data: {
        prismic: {
            allProduct_dialogues: {
                edges: any
            }
        }
    }
}

const CompanyPage: React.FC<CompanyPageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allCompanys")
    if (!doc) return null

    const normalizedValueItems = doc.values.map((value: any, index: number) => {
        return {
            text: prismicGetRichText(value.description_values),
            title: prismicGetText(value.item_title_values),
            index: `0${index + 1}`
        }
    })

    const normalizedTeamItems = doc.team.map((value: any) => {
        return {
            title: prismicGetText(value.team_member),
            memberStatus: prismicGetText(value.team_member_status),
            jobTitle: prismicGetText(value.job_title),
            link: value.read_more_link.url,
            image: value.team_member_picture.url
        }
    })

    return (
        <>
            <HeadComponent
                title="Company | Radicle"
                keywords={prismicGetText(doc.seo_keywords_company)}
                description={prismicGetText(doc.seo_description_company)}
            />
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero
                            title={prismicGetRichHeader(doc.title_company_v2)}
                            lead={prismicGetRichText(doc.lead_company)}
                            buttonLabel="Work at Radicle"
                            anchorButtonTo="#workWithUs"
                            center={true}
                            leftSideLgWidth={8}
                            headingMarginTop={0}
                        />
                    </_Section>
                    <_Section>
                        <OurValues
                            title={prismicGetRichHeader(doc.title_values_v2)}
                            leads={prismicGetText(doc.main_description_values)}
                            image={doc.image_values_new.url}
                            items={normalizedValueItems}
                        />
                    </_Section>


                    <_Section>
                        <Team
                            title={prismicGetRichHeader(doc.team_title)}
                            items={normalizedTeamItems}
                        />
                    </_Section>

                    <_Section marginBottom={"0"}>
                        <div id="workWithUs" style={{ position: "absolute", width: "100%", top: "-100px" }} />
                        <JoinRadicle
                            title={prismicGetText(doc.title_jobs)}
                            lead={prismicGetRichText(doc.lead_jobs)}
                            link={{
                                href: doc.link_to_job_openings.url,
                                title: "View Job Openings"
                            }}
                        />
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default CompanyPage
