import * as React from "react"
import { Row, Col } from "react-flexbox-grid"

import styled from "styled-components"

import { _h2 } from "../../components/shared/headline.styles"
import { _Blockquote } from "../../components/shared/blockquote.styles"

import { AppStateContext } from "../../utils/PageWrapper"

import Hexagon4 from "../../images/v2/hexagon-4.svg"
import Hexagon5 from "../../images/v2/hexagon-5.svg"

import companyShip from "../../images/v2/company-ship.svg"

const _companyShipImg = styled.img`
    width: 100%;
    max-height: 310px;
`

const _svgImageLeft = styled.img`
    position: absolute;
    right: 100%;
    z-index: -1;
`

const _svgImageRight = styled.img`
    position: absolute;
    top: -300px;
    left: 94%;
    z-index: -1;
    transform: scaleX(-1);
`

interface OurMissionProps {
    title: string | JSX.Element
    lead: string | JSX.Element
}

export const OurMission: React.FC<OurMissionProps> = ({ title, lead }) => {
    const appState = React.useContext(AppStateContext)

    return (
        <Row>
            {!appState.isMobile && <_svgImageLeft src={Hexagon4} />}

            {!appState.isMobile && (
                <Col lg={6} md={10} sm={12}>
                    <_companyShipImg src={companyShip} />
                </Col>
            )}

            <Col lg={6} md={10} sm={12}>
                <_h2 align="left">{title}</_h2>

                {appState.isMobile && (
                    <Col lg={6} md={10} sm={12}>
                        <_companyShipImg src={companyShip} />
                    </Col>
                )}

                <_Blockquote maxWidth="445px" align="left">
                    {lead}
                </_Blockquote>
            </Col>
            {!appState.isMobile && <_svgImageRight src={Hexagon5} />}
        </Row>
    )
}
