import * as React from "react"
import { Row, Col } from "react-flexbox-grid"

import { _h2 } from "../../components/shared/headline.styles"
import { _Blockquote } from "../../components/shared/blockquote.styles"
import { FullWidthSection } from "../shared"
import { _externalWhiteLinkButton } from "../shared/buttons/button.styles"

interface JoinRadicleProps {
    title: string
    lead: string | JSX.Element
    link: {
        href: string
        title: string
    }
}

export const JoinRadicle: React.FC<JoinRadicleProps> = ({ title, lead, link }) => (
    <FullWidthSection displayBottomImage={true} displayTopImage={true}>
        <Row center="xs">
            <Col lg={6} md={10} sm={12}>
                <_h2 align={"center"} fontSizeSm={42} color="var(--white)">
                    {title}
                </_h2>
                <_Blockquote color="var(--white)" align="center" alignSm="center">
                    {lead}
                </_Blockquote>

                <_externalWhiteLinkButton target="_blank" href={link.href}>
                    {link.title}
                </_externalWhiteLinkButton>
            </Col>
        </Row>
    </FullWidthSection>
)
