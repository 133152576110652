import * as React from "react"

import Slider from "react-slick"
import { Row, Col } from "react-flexbox-grid"

import { _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { SliderSettings } from "../shared"
import { _customMarginRow } from "../shared/row.styles"
import { AppStateContext } from "../../utils/PageWrapper"
import {
    _title,
    _description,
    _img,
    _index,
    _wrapper,
    _nextSlideButton,
    _itemPosition,
    _indexPosition,
    _separator
} from "./OurValues.styles"
import NextSlideButton from "../../images/v2/intelligence/next-slide-icon.svg"

interface OurValuesProps {
    title: string | JSX.Element
    leads: JSX.Element | string
    items: ItemProps[]
    image: string
}

interface ItemProps {
    index: string
    title: string
    text: string | string[]
}

export const OurValues: React.FC<OurValuesProps> = ({ title, leads, items, image }) => {
    const appState = React.useContext(AppStateContext)
    const slider = React.useRef<Slider>(null)

    const scrollToNextSlide = () => {
        if (slider && slider.current) {
            slider.current.slickNext()
        }
    }

    const findNextIndex = ({ current, items }: { current: string; items: ItemProps[] }): string => {
        const currentInt = parseInt(current)
        if (currentInt + 1 > items.length) {
            return "01"
        }

        return `0${currentInt + 1}`
    }

    const desktopItem = (item: ItemProps) => (
        <>
            <_wrapper>
                <Col sm={10} style={{ paddingTop: "8em" }}>
                    <_Blockquote maxWidth="510px" marginLeft="20px">
                        <_title>{item.title}</_title>
                        <_description>{item.text}</_description>
                        <_index>{item.index}</_index>
                    </_Blockquote>

                    <_itemPosition>
                        <_indexPosition className="current">{item.index}</_indexPosition>

                        <_separator />

                        <_indexPosition>{findNextIndex({ current: item.index, items })}</_indexPosition>
                    </_itemPosition>
                </Col>

                <Col sm={2} style={{ position: "relative" }}>
                    <_nextSlideButton onClick={scrollToNextSlide}>
                        <img src={NextSlideButton} />
                    </_nextSlideButton>
                </Col>
            </_wrapper>
        </>
    )

    const mobileItem = (item: ItemProps) => (
        <>
            <_wrapper>
                <Col sm={10}>
                    <_Blockquote>
                        <_title>{item.title}</_title>
                        <_description>{item.text}</_description>
                        <_index>{item.index}</_index>
                    </_Blockquote>
                    <_itemPosition>
                        <_indexPosition className="current">{item.index}</_indexPosition>

                        <_separator />

                        <_indexPosition>{findNextIndex({ current: item.index, items })}</_indexPosition>
                    </_itemPosition>
                </Col>
                <Col sm={2}>
                    <div style={{ position: "relative", height: '100%' }}>
                        <_nextSlideButton onClick={scrollToNextSlide}>
                            <img src={NextSlideButton} />
                        </_nextSlideButton>
                    </div>
                </Col>
            </_wrapper>
        </>
    )

    if (!appState.isReady) return null

    return (
        <>
            <_customMarginRow>
                <Col xs={12}>
                    <Row>
                        <Col xl={6} lg={8} sm={8} xs={12}>
                            <_h2 fontSizeSm={36} align="left">
                                {title}
                            </_h2>
                            <_Blockquote align="left">{leads}</_Blockquote>
                        </Col>
                    </Row>
                </Col>
            </_customMarginRow>

            <_customMarginRow center="xs" marginTop={appState.isMobile ? 0 : -8} marginBottom={4}>
                <Col md={7} sm={12}>
                    <Slider ref={slider} {...SliderSettings({ slidesToShow: 1, arrows: false, dots: false })}>
                        {items.map(item => {
                            return appState.isMobile ? mobileItem(item) : desktopItem(item)
                        })}
                    </Slider>
                </Col>
                {!appState.isMobile && (
                    <Col sm={5}>
                        <_img src={image} />
                    </Col>
                )}
            </_customMarginRow>
        </>
    )
}
