import * as React from "react"

import Slider from "react-slick"
import { Col } from "react-flexbox-grid"

import { _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { SliderSettings } from "../shared"
import { _customMarginRow } from "../shared/row.styles"
import { AppStateContext } from "../../utils/PageWrapper"
import PrevSlideButton from "../../images/company/icon-arrow-left-team.svg"
import NextSlideButton from "../../images/company/icon-arrow-right-team.svg"
import { DESKTOP_SCREEN_WIDTH, LARGE_DESKTOP_SCREEN_WIDTH, getWindowDimensions } from "../../utils/responsiveHelper"
import {
    _teamCardWrapper,
    _teamCardContentWrapper,
    _teamCardImage,
    _teamMemberStatus,
    _teamMemberTitle,
    _teamMemberJobTitle,
    _teamMemberLink,
    _nextSlideButton,
    _prevSlideButton,
    _teamCard,
    _fullWidthMobile
} from "./Team.styles"

interface TeamProps {
    title: string | JSX.Element
    items: ItemProps[]
}

interface ItemProps {
    title: string
    memberStatus: string
    jobTitle: string
    link: string
    image: string
}

export const Team: React.FC<TeamProps> = ({ title, items }) => {
    const appState = React.useContext(AppStateContext)
    const slider = React.useRef<Slider>(null)
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())
    const getSlidesToShow = () => {
        if (windowDimensions >= LARGE_DESKTOP_SCREEN_WIDTH) {
            return 3
        }

        if (windowDimensions >= DESKTOP_SCREEN_WIDTH) {
            return 2
        }

        return 1
    }

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    })

    const scrollToNextSlide = () => {
        if (slider && slider.current) {
            slider.current.slickNext()
        }
    }

    const scrollToPrevSlide = () => {
        if (slider && slider.current) {
            slider.current.slickPrev()
        }
    }

    const desktopItem = (item: ItemProps) => (
        <>
            <_teamCard>
                <_teamCardWrapper key={`team-${item.title}`}>
                    <_teamCardImage src={item.image} />
                    <_teamCardContentWrapper>
                        <_teamMemberStatus>{item.memberStatus}</_teamMemberStatus>
                        <_teamMemberTitle>{item.title}</_teamMemberTitle>
                        <_teamMemberJobTitle>{item.jobTitle}</_teamMemberJobTitle>
                        <_teamMemberLink href={item.link}>Read more</_teamMemberLink>
                    </_teamCardContentWrapper>
                </_teamCardWrapper>
            </_teamCard>
        </>
    )

    if (!appState.isReady) return null

    return (
        <>
            <_customMarginRow marginBottom={"0"}>
                <Col xs={12}>
                    <_h2 fontSizeSm={36} align={"center"} marginBottom={"0"}>
                        {title}
                    </_h2>
                </Col>
            </_customMarginRow>

            <_fullWidthMobile>
                <_customMarginRow center="md" marginBottom={5}>
                    {!appState.isMobile && items.length > 3 && (
                        <_prevSlideButton onClick={scrollToPrevSlide}>
                            <img src={PrevSlideButton} />
                        </_prevSlideButton>
                    )}
                    <Col lg={12} md={10} sm={12}>
                        <Slider
                            ref={slider}
                            {...SliderSettings({
                                slidesToShow: getSlidesToShow(),
                                slidesToScroll: getSlidesToShow(),
                                arrows: false,
                                dots: appState.isMobile,
                                extraDotsClass: "mobileBlue",
                                centerMode: appState.isMobile,
                            })}>
                            {items.map(item => {
                                return desktopItem(item)
                            })}
                        </Slider>
                    </Col>
                    {!appState.isMobile && items.length > 3 && (
                        <_nextSlideButton onClick={scrollToNextSlide}>
                            <img src={NextSlideButton} />
                        </_nextSlideButton>
                    )}
                </_customMarginRow>
            </_fullWidthMobile>
        </>
    )
}
