import styled from "styled-components"

import { _description, _h3 } from "../shared/headline.styles"

export const _teamCardWrapper = styled.div`
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background-color: var(--white);
    text-align: left;
`

export const _teamCardImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`

export const _teamMemberStatus = styled(_description)`
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 14px;
    color: #2e2e68;
`

export const _teamCardContentWrapper = styled.div`
    padding: 26px 16px 16px;

    @media only screen and (min-width: 992px) {
        min-height: 185px;
    }

    @media only screen and (max-width: 768px) {
        padding: 16px;
    }
`

export const _teamMemberTitle = styled(_h3)`
    text-align: left;
    margin: 0;

    @media only screen and (max-width: 768px) {
       font-size: 21px;
    }
`

export const _teamMemberJobTitle = styled(_h3)`
    text-align: left;
    font-weight: normal;
    margin: 0 0 13px;
    min-height: 32px;

    @media only screen and (max-width: 768px) {
        font-size: 21px;
    }
`

export const _teamMemberLink = styled.a`
    font-family: Nunito;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--warm-blue);
    cursor: pointer;

    @media only screen and (max-width: 768px) {
        font-size: 16px;
    }
`

export const _nextSlideButton = styled.button`
    border: none;
    background: none;
    outline: none;
    position: absolute;
    cursor: pointer;

    right: -20px;
    bottom: 50%;
    left: unset;
    margin-bottom: -75px;
    z-index: 1;

    @media only screen and (min-width: 1340px) {
        right: -75px;
    }
`

export const _prevSlideButton = styled.button`
    border: none;
    background: none;
    outline: none;
    position: absolute;
    cursor: pointer;

    left: -20px;
    bottom: 50%;
    right: unset;
    margin-bottom: -75px;
    z-index: 1;

    @media only screen and (min-width: 1340px) {
        left: -75px;

    }
`

export const _teamCard = styled.div`
    padding: 17px;

    @media only screen and (max-width: 768px) {
        padding: 10px;
    }
`

export const _fullWidthMobile = styled.div`
    @media only screen and (max-width: 768px) {
        width: 100vw;
        margin-left: calc((100% - 100vw) / 2);
        position: relative;
        overflow: hidden;
    }
`
